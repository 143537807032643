<template>
  <div>
    <v-container>
      <h2 class="text-h5 mb-4">Shops &nbsp;&nbsp; (Total: {{ shops.total }})</h2>
      <v-card class="mb-4" style="padding: 20px">
        <v-row>
          <v-col md="2">
            <v-text-field
              v-model="filters.search_keyword"
              label="Search Keyword"
              outlined
              @change="fetchShops()"
              dense
              placeholder="Search Keyword"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <v-select
              style="margin-right: 10px"
              v-model="filters.shop_state"
              dense
              :items="['All', 'Active', 'Uninstalled']"
              @change="fetchShops()"
              label="Shop State"
              outlined
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>
      <v-data-table :headers="headers" :items="shops.data" class="elevation-1" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-for="item in items" :key="item.id">
              <td>
                <i>#{{ item.id }}</i>
              </td>
              <td>{{ item.specifier }}</td>
              <td>{{ item.token }}</td>
              <td>{{ item.user && item.user.phone != null ? item.user.phone : (item.uninstalled_client && item.uninstalled_client.shop_phone != null ? item.uninstalled_client.shop_phone  : 'Not Available') }}</td>
              <td>{{ item.user && item.user.email != null ? item.user.email : (item.uninstalled_client && item.uninstalled_client.shop_email != null ? item.uninstalled_client.shop_email  : 'Not Available') }}</td>
              <td>
                <v-chip small class="ma-2" color="green" text-color="white" v-if="item.app_status == '1'"
                  >Active</v-chip
                >
                <v-chip small class="ma-2" color="red" text-color="white" v-else>Inactive</v-chip>
              </td>
              <td>
                <span>
                  <v-chip
                    v-if="item.review"
                    small
                    class="ma-2"
                    :color="item.review.app_rating == 0 ? 'red lighten-4' : 'green lighten-4'"
                    :text-color="item.review.app_rating == 0 ? 'red' : 'green'"
                  >
                    {{ item.review.app_rating == 0 ? 'No' : 'Yes' }}
                  </v-chip>
                  <v-chip small class="ma-2" color="red" text-color="white" v-else>No</v-chip>
                </span>
              </td>
              <td>
                {{ getTimeDifference(item.last_feed_refresh) }}
              </td>
              <td>
                <v-btn fab small color="primary darken-1" class="table-action--button" :loading="isShopLoading(item.id)" @click="loginAndOpenShopDetail(item.id)">
                <v-progress-circular
                  v-if="isShopLoading(item.id)"
                  indeterminate
                  color="white"
                  size="20"
                ></v-progress-circular>
                <v-icon v-else small dark>{{ mdiLogin }}</v-icon>
              </v-btn>
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-pagination
        v-model="shops.current_page"
        class="my-4"
        @input="handlePageChange"
        :length="shops.last_page"
        :total-visible="7"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import { mdiLogin, mdiLoading } from '@mdi/js'
import moment from "moment";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      shopLoadingStates: {},
      loading_data: false,
      is_first: true,
      selectedRating: 0,
      filters: {
        search_keyword: '',
        shop_state: ''
      },
      shop: {
        etsy_shop: {},
      },
      headers: [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Token', value: 'token' },
        { text: 'Phone', value: 'phone', sortable: false },
        { text: 'Email', value: 'email', sortable: false },
        { text: 'App Status', value: 'app_status', sortable: false },
        { text: 'Rating', value: 'rating', sortable: false },
        { text: 'Last Synced At', value: 'last_feed_refresh' },
        { text: 'Actions', value: 'actions' },
      ],
      shops: {
        current_page: 1,
        data: [],
      },

      //icons
      mdiLogin: mdiLogin,
      mdiLoading: mdiLoading
    }
  },
  mounted() {
    this.fetchShops()
  },
  methods: {
    ...mapActions(["LoginAsClient"]),
    handlePageChange(value) {
      this.fetchShops()
    },
    async fetchShops() {
      try {
        const currentPath = this.$route.path;
        let syncDelayedTimeinMinutes = '15';
        if (currentPath == '/shops') {
          syncDelayedTimeinMinutes ='';
        }

        // if (this.is_first == true)
        this.loading_data = true
        let response = await axios.get('admin/shops', {
          params: {
            page: this.shops.current_page,
            keyword: this.filters.search_keyword,
            state: this.filters.shop_state,
            min_last_synced_time: syncDelayedTimeinMinutes
          },
        })
        if (response.data.success) {
          this.shops = response.data.shops
          this.is_first = false
        }
      } catch (e) {
      } finally {
        this.loading_data = false
      }
    },

    async getShopDetail(shopId) {
      try {
        this.is_loading = true;
        let response = await axios.get(`admin/shops/${shopId}/get-detail`);
        if (response.data.success) {
          this.shop = response.data.shop;
        }
      } catch (e) {
      } finally {
        this.is_loading = false;
      }
    },

    async loginAndOpenShopDetail(shopId){
      this.setShopLoadingState(shopId, true);
      await this.getShopDetail(shopId);
      console.log(this.shop)
      await this.loginShop();
      window.location = "/shops/"+shopId;
    },

    async loginShop() {
      console.log(this.shop)
      let resposne = await this.LoginAsClient(this.shop);
      if (resposne.success != true) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Email or password mismatch!",
        });
      } else {
        console.log("Logged in as Client Successfully!")
      }
    },

    getTimeDifference(lastRefreshTimestamp) {
      const lastRefreshMoment = moment.utc(lastRefreshTimestamp);
      const nowUtcMoment = moment.utc();
      const timeDifferenceMinutes = nowUtcMoment.diff(lastRefreshMoment, 'minutes');
      const formattedTimeDifference = moment.duration(timeDifferenceMinutes, 'minutes').humanize();
      return formattedTimeDifference + ' ago';
    },

  setShopLoadingState(shopId, isLoading) {
    this.$set(this.shopLoadingStates, shopId, isLoading);
  },

  isShopLoading(shopId) {
    return this.shopLoadingStates[shopId] || false;
  },
}
}
</script>
